import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DefaultEventFragmentDoc } from './defaultEvent.graphql';
import { OperationFragmentDoc } from './operation.graphql';
import { DiseaseFragmentDoc } from './disease.graphql';
import { InjectionFragmentDoc } from './injection.graphql';
import { ProtocolFragmentDoc } from './protocol.graphql';
import { InseminationSchemeFragmentDoc } from '../../../inseminationSchemes/gql/fragments/inseminationScheme.graphql';
export type Event_InseminationScheme_Fragment = { __typename: 'InseminationScheme', name: string, kind: Types.EventKindEnum, id: string, code: string, isDefault: boolean };

export type Event_Protocol_Fragment = { __typename: 'Protocol', name: string, kind: Types.EventKindEnum, id: string, expectedDaysOnProtocol?: number | null, daysUntilCheckAfterLastThreatment?: number | null, daysMeatWithhold: number, daysMilkWithhold: number, totalTreatmentCost?: number | null, penGroup?: { __typename: 'PenGroup', id: string } | null, protocolInjections: Array<{ __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null }> };

export type Event_Disease_Fragment = { __typename: 'Disease', name: string, kind: Types.EventKindEnum, id: string, protocols: Array<{ __typename: 'Protocol', id: string }> };

export type Event_Injection_Fragment = { __typename: 'Injection', name: string, kind: Types.EventKindEnum, id: string, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean };

export type Event_Operation_Fragment = { __typename: 'Operation', name: string, kind: Types.EventKindEnum, id: string };

export type Event_DefaultEvent_Fragment = { __typename: 'DefaultEvent', name: string, kind: Types.EventKindEnum, shortcode: Types.DefaultEventEnum, description: string };

export type EventFragment = Event_InseminationScheme_Fragment | Event_Protocol_Fragment | Event_Disease_Fragment | Event_Injection_Fragment | Event_Operation_Fragment | Event_DefaultEvent_Fragment;

export const EventFragmentDoc = gql`
    fragment Event on Event {
  __typename
  name
  kind
  ...DefaultEvent
  ...Operation
  ...Disease
  ...Injection
  ...Protocol
  ...InseminationScheme
}
    ${DefaultEventFragmentDoc}
${OperationFragmentDoc}
${DiseaseFragmentDoc}
${InjectionFragmentDoc}
${ProtocolFragmentDoc}
${InseminationSchemeFragmentDoc}`;